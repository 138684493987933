import * as React from 'react';

const PreviewLayout = ({children}) => {
  return (
    <main className='py-24 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
      {children}
    </main>
  )
}

export default PreviewLayout;