import { createClient } from '@sanity/client';

const projectId = "t5kvbwq7";
const envDataset = "production";
const apiVersion = "2023-07-01";

export function getSanityPreviewClient (
  preview = {},
  previewDataset = envDataset,
) {
  const client = createClient({
    projectId,
    dataset: previewDataset,
    apiVersion,
    useCdn: false,
    token: preview.token
  })
  return client;
}