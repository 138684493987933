exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-previews-color-scheme-jsx": () => import("./../../../src/pages/_previews/colorScheme.jsx" /* webpackChunkName: "component---src-pages-previews-color-scheme-jsx" */),
  "component---src-pages-previews-content-block-jsx": () => import("./../../../src/pages/_previews/contentBlock.jsx" /* webpackChunkName: "component---src-pages-previews-content-block-jsx" */),
  "component---src-pages-previews-form-jsx": () => import("./../../../src/pages/_previews/form.jsx" /* webpackChunkName: "component---src-pages-previews-form-jsx" */),
  "component---src-pages-previews-page-jsx": () => import("./../../../src/pages/_previews/page.jsx" /* webpackChunkName: "component---src-pages-previews-page-jsx" */),
  "component---src-pages-previews-section-jsx": () => import("./../../../src/pages/_previews/section.jsx" /* webpackChunkName: "component---src-pages-previews-section-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */)
}

